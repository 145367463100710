/* Überschrift */

h1 {
	padding-bottom: 0 !important;
}
h1::before {
	display: none !important;
}

/* Teaser - Slider */

.teaser-slider-item__date {
	background: #900828 !important;
}
.teaser-slider-item__time {
	background: #4f4f4a !important;
}
.teaser-slider-item__time::before {
	border-color: transparent #373733 transparent transparent !important;
}

/* Speisen */

.mco-list-meal-item__price {
	display: none !important;
}

/* Interaktionslayer */

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	background: #d90000 !important;
	border: 0 !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	cursor: pointer !important;
	font-size: 18px !important;
	font-weight: 500 !important;
	line-height: 1.2 !important;
	min-width: 64px !important;
	padding: 12px 16px !important;
	text-align: center !important;
	text-transform: uppercase !important;
	transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: #3c3c3a !important;
}
.micado-ial__notification .micado-ial__close,
.micado-ial__message .micado-ial__close {
	background: #eee9e3 !important;
}
.micado-ial__notification .micado-ial__close .micado-svg-icon svg path,
.micado-ial__message .micado-ial__close .micado-svg-icon svg path {
	fill: #4f4f4a !important;
}
.micado-ial__notification .micado-ial__close:hover,
.micado-ial__message .micado-ial__close:hover {
	background: #575654 !important;
}
.micado-ial__notification .micado-ial__close:hover .micado-svg-icon svg path,
.micado-ial__message .micado-ial__close:hover .micado-svg-icon svg path {
	fill: #ffffff !important;
}
.micado-ial-item-notification__button--is-icon {
	background: #d90000 !important;
}
.micado-ial-item-notification__button--is-icon:hover {
	background: #3c3c3a !important;
}
.micado-ial__close,
.micado-overlay__close {
	background: #eee9e3 !important;
}
.micado-ial__close svg path,
.micado-overlay__close svg path {
	fill: #4f4f4a !important;
}
.micado-ial__close:hover,
.micado-overlay__close:hover {
	background: #3c3c3a !important;
	color: #ffffff !important;
}
.micado-ial__close:hover svg path,
.micado-overlay__close:hover svg path {
	fill: #ffffff !important;
}
.micado-ial-item-contact__headline,
.micado-ial-item-countdown__headline,
.micado-ial-item-message__headline,
.micado-ial-item-newsletter__headline {
	color: #4f4f4a !important;
	font-size: 32px !important;
}
.micado-ial-item-countdown__box-value {
	color: #4f4f4a !important;
	font-size: 28px !important;
}
.micado-ial-item-countdown__box-label {
	font-size: 14px !important;
}
.micado-ial-item-contact__headline,
.micado-ial-item-newsletter__headline {
	text-align: left !important;
}
.micado-ial-item-contact__text,
.micado-ial-item-newsletter__text {
	text-align: left !important;
}
.micado-ial-item-message__buttons {
	justify-content: flex-end !important;
}
.micado-ial-item-offer__headline {
	text-align: center !important;
}
